.WORKS{
	.localNav{
		background: #f2f2f2;
		padding: 24px 56px;
		border-radius: 6px;
		ul{
			@include flex();
			li{
				list-style: none;
				width: 23%;
				@media screen and (max-width:768px){
					width: 100%;
				} 
				a{
					display: block;
					text-align: center;
					color: $normal;
					font-size: 17px;
					font-weight: 700;
					background: #FFF;
					border: #FFF 1px solid;
					border-radius: 4px;
					padding: 12px 0;
					box-shadow: 0 0 24px rgba(#000,.12);
					&.active{
						color: $blue;
						background: #FFF;
						border: $blue 1px solid;
					}
					&:hover{
						background: $blue;
						border: $blue 1px solid;
						color: #FFF;
					}
				}
			}
		}
	}
	.listable{
		@include flex($child:flex-start);
		li{
			list-style: none;
			width: 23%;
			background: #FFF;
			box-shadow: 0 4px 16px rgba(#000,.12);
			margin: 0 1% 24px;
            @media screen and (max-width:768px){
                width: 90%;
				margin: 0 auto 32px;
            } 
			a{
				display: block;
			}
			figure{
				img{
					width: 100%;
					height: auto;
				}
				figcaption{
					text-align: center;
					font-size: 14px;
					padding: 8px 15px;
					color: $normal;
				}
			}
		}
	}
}