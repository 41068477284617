@charset "utf-8";
#FOOTER{
	background: $blue;
	padding: 48px 0;
	color: #FFF;
	@media screen and (max-width:768px){
		padding: 32px 15px;
	}
	a{
		color: #FFF;
	}
	.tit{
		float: left;
		width: 30%;
		@media screen and (max-width:768px){
			width: auto;
			float: none;
		}
		dt{
			font-size: 24px;
			font-weight: 700;
			img{
				width: 300px;
			}
			@media screen and (max-width:768px){
				font-size: 20px;
			}
		}
		dd{
			&.description{
				margin: 12px 0 0;
				@media screen and (max-width:768px){
					margin: 4px 0 0;
				}
			}
		}
	}
	.fnav{
		width: 70%;
		float: right;
		padding: 0 0 0 72px;
		@media screen and (max-width:768px){ 
			width: auto;
			float: none;
			padding: 0;
		}
		ul{
			float: right;
			@media screen and (max-width:768px){
				float: none;
				margin: 24px 0 0;
			}
			li{
				list-style: none;
				float: left;
				font-size: 13px;
				@media screen and (max-width:768px){
					float: none;
					font-size: 15px;
					margin: 0 0 1px;
					a{
						display: block;
						padding: 10px 15px;
						background: #FFF url(../../images/common/arrow_icon_glay_2x.png) no-repeat right 15px center;
						@include background(5px);
						color: $normal;
						font-size: 13px;
						font-weight: 700;
						text-decoration: none;
					}
				}
				&:after{
					content: '|';
					margin: 0 8px;
					opacity: .64;
					display: inline-block;
					@media screen and (max-width:768px){
						display: none;
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
		small{
			display: block;
			clear: both;
			text-align: right;
			padding: 80px 0 0;
			font-size: 13px;
			@media screen and (max-width:768px){
				padding: 24px 0 0;
				text-align: center;
			}
		}
	}
}