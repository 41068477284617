@charset "utf-8";

.SINGLE {
	&.list {
		.oneClum {
			>li {
				list-style: none;
				padding: 0 0 12px;
				margin: 0 0 12px;
				//sp style
				@media screen and (max-width:768px){
					padding-bottom: 0;
				}
				a {
					display: block;
					@include shadow;
					padding: 20px;
					color: $normal;
					font-size: 16px;
					background: url("../../images/common/arrow_03_2x.png") no-repeat right 20px center;
					@include background(4px 7px);
					//sp style
					@media screen and (max-width:768px){
						padding: 15px;
						font-size: 13px;
						background-position: right 12px center;
					}
					&:hover {
						text-decoration: none;
						background-position: right 17px center;
						@include trance(200ms);
					}
					time {
						display: inline-block;
						padding: 3px 15px;
						background: $blue;
						color: #FFF;
						font-size: 12px;
						margin-right: 12px;
						@media screen and (max-width:768px){
							display: block;
							width: 100px;
							margin: 0 0 5px;
						}
						&.green{
							background: lighten($green,20%);
						}
					}
				}
			}
		}
	}
	&.detail {
		.dateWrite {
			display: block;
			border-top: 1px solid $border;
			margin: 32px 0 0;
			padding: 12px 0 0;
		}
		.postlink {
			text-align: center;
		}
	}
}
