@charset "utf-8";
.COMPANY{
	&.first{
		background: url(../../images/company/bg.png) no-repeat left calc(50% + 360px) bottom;
		dl{
			text-align: center;
			@media screen and (max-width:768px){
				width: 88%;
				margin: 0 auto;
			}
			dt{
				@include min();
				font-size: 28px;
				font-weight: 700;
				color: darken($blue,16%);
				letter-spacing: .32em;
				margin: 0 0 20px;
				@media screen and (max-width:768px){
					margin: 0 0 15px;
				}
			}
			dd{
				line-height: 2.4;
				@media screen and (max-width:768px){
					line-height: 2;
					text-align: justify;
					br{
						display: none;
					}
				}
			}
		}
	}
	&.second{
		.grid{
			figure{
				width: 16%;
				padding: 0 2% 0 0;
				float: left;
				@media screen and (max-width:768px){
					width: 32%;
					margin: 0 2% 0 0;
					&:last-of-type{
						margin: 0;
					}
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: 50%;
				float: right;
				@media screen and (max-width:768px){
					width: auto;
					float: none;
					clear: both;
					padding: 24px 0 0;
				}
				dt{
					color: $blue;
					font-size: 20px;
					font-weight: 700;
					margin: 0 0 8px;
					@media screen and (max-width:768px){
						font-size: 16px;
					}
				}
				dd{
					font-size: 14px;
					text-align: justify;
				}
			}
		}
		.text{
			clear: both;
			background: #FFF;
			padding: 30px 50px;
			margin: 40px 0 0;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
				padding: 15px;
			}
		}
	}
	&.third{
		.tableStyle{
			@media screen and (max-width:768px){
				tbody{
					tr{
						th{
							text-align: left;
						}
					}
				}	
			}
			dl{
				width: 47%;
				float: left;
				border-right: rgba(#000,.08) 1px solid;
				&:nth-of-type(2n+2){
					float: right;
					border: none;
				}
				@media screen and (max-width:768px){
					width: auto;
					float: none;
					margin: 0 0 24px;
					border: none;
					&:nth-of-type(2n+2){
						margin: 0;
						float: none;
					}
				}
				dt{
					background: $blue;
					color: #FFF;
					text-align: center;
					display: inline-block;
					padding: 2px 24px;
					margin: 0 0 12px;
					@media screen and (max-width:768px){
						display: block;
						text-align: center;
						padding: 4px 0;
					}
				}
			}
		}
		#addMap{
			margin: 48px 0 0;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
				height: 270px;
			}
		}
	}
}