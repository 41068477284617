.CONTACT-TOP{
	ul{
		@include flex();
		li{
            list-style: none;
            width: 48%;
            margin: 0 1% 2%;
			@media screen and (max-width:768px){
				width: 100%;
			}
			dl{
                background: #f2f2f2;
                display: block;
                padding: 32px;
				dt{
                    font-size: 20px;
                    font-weight: bold;
					margin-bottom: 16px;
                    @media screen and (max-width:768px){
                        font-size: 18px;
                    }
				}
				dd{
				    margin-bottom: 8px;
				}
				.btn{
					margin-top: 16px;
                    @media screen and (max-width:768px){
                        width: 100%;
                    }
					a{
						color: #fff;
						font-weight: bold;
						background: $blue;
						padding: 16px;
						transition: .3s;
						border-radius: 8px;
					&:hover{
						border: 1px solid #111;
						background: #f2f2f2;;
						color: #111;
					}
					}
				}
			}
		}
	}
}