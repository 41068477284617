@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#353535;
$link:#D00;
$border:#e5e5e5;
$red:#D22;
$green:#2b865a;
$blue:#05a1f2;
$yellow:#f2ea05;
$orange:#f2b704;
$gray:#f2f2f2;
$sky:#edf8fe;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:80px;
$sp-head:66px;



@media screen and (min-width:769px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:768px){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/breadcrumb";
@import "page/index";
@import "page/company";
@import "page/sitemap";
@import "page/single";
@import "page/works";
@import "page/selling";
@import "page/estate";
@import "page/rental";
@import "page/contact-top";
@import "page/works-single";

#WRAPPER,#FOOTER{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}

.MODULE{
	&.wrap{
		padding: 72px 0;
		@media screen and (max-width:768px){
			padding: 40px 15px;
		}
		&.noBottom{
			padding-bottom: 0;
		}
		&.noTop{
			padding-top: 0;
		}
		&.glay{
			background: #f2f2f2;
		}
		&.sky{
			background: #edf8fe;
		}
	}
	h2.lang{
		text-align: center;
		font-size: 14px;
		font-weight:700;
		margin: 0 0 24px;
		letter-spacing: .05em;
		&:before {
			content: attr(data-eng);
			font-size: 38px;
			font-weight: 900;
			display: block;
			padding: 0 0 8px;
			color: darken($blue,2%);
		}
	}
	h3 {
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		margin: 0 0 24px;
		letter-spacing: .05em;
		&:before {
			content: attr(data-eng);
			font-size: 14px;
			display: block;
			padding: 0 0 8px;
		}
	}
	.center{
		text-align: center;
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 32px;
	}
	h3.borders{
		@include font();
		font-size:20px;
		font-weight:700;
		line-height:1.2;
		padding:0 0 8px 16px;
		margin:0 0 20px;
		position:relative;
		text-align: left;
		top:0;
		left:0;
		border-bottom:rgba(#000,.08) 1px solid;
		&.gr{
			&:before{
				background: $green;
			}
		}
		@media only screen and (max-width:768px){
			font-size: 17px;
			margin: 0 0 12px;
		}
		&:before{
			content:'';
			width:4px;
			height:80%;
			background: $blue;
			position:absolute;
			top:0;
			left:0;
		}
		.numb{
			color: $red;
			font-size: 24px;
			line-height: 1;
			margin: 0 4px;
		}
	}

	h4{
		font-size: 20px;
		font-weight: 700;
		padding:16px 0;
		margin: 0 0 32px;
		position: relative;
		@media screen and (max-width:768px){
			font-size: 18px;
			margin: 0 0 24px;
		}
		&.borderStyle{
			border-bottom: $border 1px solid;
			border-top: $border 1px solid;
			padding-left: 16px;
			&:before{
				content: '';
				width: 4px;
				height: 50%;
				background: $blue;
				position: absolute;
				top: 25%;
				left: 0;
			}
		}
		&.boldStyle {
			border-bottom: $border 1px solid;
			margin-bottom: 15px;
			a {
				display: inline-block;
				font-size: 12px;
				color: #FFF;
				padding: 5px 12px;
				position: absolute;
				top: 50%; right: 0;
				@include transform(0,-50%);
			}
			&.blue {
				&:first-letter {
					color: $blue;
				}
				a {
					background: $blue;
				}
			}
			&.yellow {
				&:first-letter {
					color: #f2c305;
				}
				&:after {
					content: attr(data-title);
					display: inline-block;
					font-size: 12px;
					font-weight: 700;
					margin-left: 5%;
				}
				a {
					background: #f2c305;
				}
			}
		}
	}
	.formStyle{
		border:none;
		border-collapse:collapse;
		width:100%;
		@include font();
		tbody{
			tr{
				th,td{
					padding:32px 4px;
					border-bottom:$border 1px solid;
					text-align:left;
					vertical-align:middle;
					@media only screen and (max-width:768px){
						padding:14px 2px;
					}
				}
				th{
					width:22%;
					text-align:left;
					@media only screen and (max-width:768px){
						width:100%;
						font-size:16px;
						border-bottom: none;
						padding-bottom: 0;
					}
					&.hiss{
						position:relative;
						top:0;
						left:0;
						&:before{
							content:'必須';
							display:inline-block;
							padding: 4px 12px;
							color:#FFF;
							background:darken($blue,12%);
							position: absolute;
							top: 50%;
							right: 0;
							margin: -16px 0 0;
							border-radius: 4px;
							@media only screen and (max-width:768px){
								font-size:12px;
								margin:-14px 0 0;
							}
						}
					}
				}
				td{
					padding-left:40px;
					@media only screen and (max-width:768px){
						padding-left:0;
					}
					input[type=text],
					input[type=tel],
					input[type=email],
					textarea{
						display:block;
						background:#f2f2f2;
						border:none;
						border-radius: 4px;
						@include font();
						font-size:15px;
						width:100%;
						padding:8px;
						@include trance(120ms);
						&:focus{
							transform: scale(1.01);
							background:#FFF;
							box-shadow:0 0 12px rgba(0,0,0,.16);
						}
					}
					label{
						vertical-align:middle;
						input{
							display:inline-block;
							margin:0 4px 0 0;
						}
					}
					textarea{
						height:200px;
					}
				}
			}
		}
		@media only screen and (max-width:768px){
			display:block;
			tbody,tr,th,td{
				display:block;
			}
		}
	}
	.send{
		margin:32px 0 0;
		text-align:center;
		input{
			border:none;
			width:240px;
			height:48px;
			@include font();
			color:#FFF;
			font-size:20px;
			font-weight:700;
			background:darken($blue,4%);
			cursor:pointer;
			&:hover{
				background:darken($blue,8%);
			}
		}
	}

	.btnArea{
		text-align: center;
		margin: 24px 0 0;
		@media screen and (max-width:768px){
			margin: 16px 0 0;
		}
		a{
			display: inline-block;
			background-color: $blue;
			color: #FFF;
			font-size: 16px;
			font-weight: 700;
			text-decoration: none;
			padding: 12px 48px;
			@include trance(200ms);
			@media screen and (max-width:768px){
				padding: 12px 0;
				display: block;
			}
			&:hover{
				background-color: lighten($blue,8%);
			}
			&.arw{
				background-image: url(../images/common/arrow_w_2x.png);
				background-repeat: no-repeat;
				background-position: right 16px center;
				@include background(13px);
				&:hover{
					background-position: right 12px center;
				}
			}
		}
	}
	.inputArea{
		label{
			display: block;
			input[type=text],
			input[type=email],
			input[type=tel],
			input[type=password]{
				width: 72%;
				@include font();
				padding: 3px 8px;
				border: none;
				background: #F2F2F2;
				@include radius(4px);
			}
		}
	}	
	
	.tableStyle{
		border: rgba(#000,.08) 1px solid;
		border-collapse: collapse;
		width: 100%;
		@media screen and (max-width:768px){
			display: block;
			tbody,tr,th,td{
				display: block;
			}
		}
		tbody{
			tr{
				th,td{
					padding: 15px 20px;
					border: rgba(#000,.08) 1px solid;
					vertical-align: middle;
					text-align: left;
					@media screen and (max-width:768px){
						padding: 15px;
					}
				}
				th{
					background: #f2f2f2;
					width: 24%;
					text-align: center;
					@media screen and (max-width:768px){
						width: 100%;
					}
				}
				td{
					
				}
			}
		}
		&.formStyle{
			border: none;
			tbody{
				tr{
					th{
						background: none;
						font-size: 16px;
						@extend .clearfix;
						&.hiss{
							&:before{
								content: '必須';
								background: $blue;
								float: right;
								font-size: 13px;
								padding: 4px 24px;
								color: #FFF;
							}
						}
					}
					th,td{
						padding: 40px 2px;
						text-align: left;
						border: none;
						border-bottom: $border 1px solid;
						@media screen and (max-width:768px){
							padding: 15px 0;
						}
					}
					td{
						padding-left: 56px;
						@media screen and (max-width:768px){
							padding-left: 0;
						}
					}
				}
			}
			label.lb{
				display: inline-block;
				margin: 0 32px 0 0;
				font-size: 17px;
				cursor: pointer;
				input[type=radio]{
					margin: 0 5px 0 0;
					vertical-align: baseline;
				}
				&:last-of-type{
					margin: 0;
				}
			}
			input[type=text],
			input[type=password],
			input[type=tel],
			input[type=email],
			textarea{
				@include font();
				font-size: 15px;
				border: none;
				padding: 6px 12px;
				width: 100%;
				background: #f2f2f2;
				@include radius(4px);
				display: block;
				@include trance(200ms);
				&:focus{
					background: #FFF;
					box-shadow: 0 0 36px rgba(#000,.24);
				}
			}
			textarea{
				height: 200px;
				max-height: 200px;
				min-height: 200px;
				&:disabled{
					color: $blue;
					font-weight: 700;
					background: #FFF;
					border: $border 1px solid;
				}
			}
		}
	}
	.sendBtn{
		text-align: center;
		margin: 32px 0 0;
		.mes{
			padding: 20px 0;
			color: #d22;
			font-size: 18px;
			font-weight: 700;
			display: none;
		}
		input{
			@include font();
			font-size: 20px;
			font-weight: 700;
			background: $blue;
			color: #FFF;
			border: $blue 1px solid;
			cursor: pointer;
			padding: 6px 80px;
			@include radius(4px);
			margin: 0 8px;
			@media screen and (max-width:768px){
				padding: 6px 0;
				display: block;
				margin: 0 0 12px;
				width: 100%;
				font-size: 16px;
			}
			&:hover{
				background: #FFF;
				color:$blue;
			}
		}
	}
	.inputBtn{
		background: #f2f2f2;
		color: #FFF;
		border: none;
		@include radius(4px);
		display: block;
		font-size: 16px;
		font-weight: 700;
		cursor: pointer;
		&:hover{
			opacity: .8;
		}
		&.ib{
			display: inline-block;
			width: 152px;
		}
		&.sb {
			width: 81px;
		}
		&.colorBlue{
			background: $blue;	
		}
		&.lightBlue{
			background: lighten($blue,16%);
		}
	}
	.searchBlock{
		.searchBox{
			margin: 0 0 12px;
			&.inline{
				display: inline-block;
				margin: 0 24px 0 0;
				@media screen and (max-width:768px){
					display: block;
				}
				&:last-of-type{
					margin: 0;
				}
			}
			&:last-of-type{
				margin: 0;
			}
			label.radioSet{
				cursor: pointer;
				font-weight: 500;
				input[type=checkbox]{
					display: inline-block;
					margin: 0 10px 0 0;
					@include transform(0,-1px);
				}
				select{
					@include font();
					font-size: 16px;
					border: $border 2px solid;
					padding: 6px 8px;
				}
			}
			.underText{
				font-size: 12px;
				margin: 3px 0 0;
			}
		}
		&:last-of-type{
			margin: 0;
		}
	}
	#addMap{
		width: 100%;
		height: 300px;
		iframe{
			vertical-align: bottom;
		}
	}
	.pager{
		text-align: center;
		margin: 32px 0 0;
		a,span{
			$size:32px;
			line-height: $size;
			display: inline-block;
			color: $blue;
			background: #FFF;
			border: $blue 1px solid;
			margin: 0 4px;
			text-decoration: none;
		}
		span{
			border: $blue 1px solid;
			background: $blue;
			color: #FFF;
		}
		a{
			&:hover{
				background: #FFF;
				color: $blue;
			}
		}
	}
}

.fixed{
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 100;
	@include transform(0,-50%);
	@media screen and (max-width:768px){
		display: none;
	} 
}
 
#addMap{
	width: 100%;
	height: auto;
	@media screen and (max-width:768px){
		height: 350px;
	}
	iframe{
		vertical-align: bottom;
	}
}

