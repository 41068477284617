@charset "utf-8";

.RENTAL{
	&.first{
		.cap{
			@include flex();
			li{
				list-style: none;
				width: 32%;
				background: #FFF;
				border: $blue 1px solid;
				border-radius: 6px;
				margin: 0 0 16px;
				@media screen and (max-width:768px){
					width: 96%;
					margin: 0 auto 16px;
				}
				&:nth-last-child(-n+3){
					margin-bottom: 0;
                    @media screen and (max-width:768px){
                        margin-bottom: 16px;  
					}
				}
				dl{
					dt{
						font-size: 16px;
						text-align: center;
						background: $blue;
						color: #FFF;
						padding: 4px 0;
						margin: 0 !important;
					}
					dd{
						padding: 12px 15px;
						font-size: 13px;
						text-align: justify;
					}
				}
			}
		}
	}
}