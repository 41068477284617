.SELLING{
	&.first{
		p.context{
			padding: 32px 16px;
			background: rgba($blue,.08);
			border-radius: 6px;
			text-align: center;
			font-size: 17px;
			color: darken($blue,24%);
			font-weight: 700;
		}
		.floating{
			@extend .clearfix;
			margin: 0 0 40px;
			&:last-of-type{
				margin: 0;
			}
			figure{
				width: 30%;
				float: left;
				@media screen and (max-width:768px){
                    width: 80%;
                    margin: 0 auto 32px;
                    box-shadow: 4px 4px 4px 4px rgba(0,0,0,.04);
					float: none;
				}
				img{
					width: 100%;
				}
			}
			.text{
				width: 70%;
				float: right;
				padding: 0 0 0 40px;
				@media screen and (max-width:768px){ 
                    width: 96%;
                    float: none;
                    margin: auto;
                    padding: 0;
				}
				dt{
					font-size: 18px;
					font-weight: 700;
					margin: 0 0 16px;
					position: relative;
					@media screen and (max-width:768px){
						font-size: 16px;
					}
					&.dotto{
						font-size: 20px;
						border-bottom: $border 1px solid;
						padding: 0 0 12px 20px;
						&:before{
							$size: 13px; 
							content: '';
							width: $size;
							height: $size;
							border: $blue 4px solid;
							border-radius: 50%;
							position: absolute;
							top: 12px;
							left: 0;
						}
					}
				}
				dd{
					text-align: justify;
				}
			}
		}
	}
}