@charset "utf-8";

@mixin headerStyle(
	$header-width:auto,
	$header-height:auto,
	$header-x:0,
	$header-y:0,
	$header-top-padding:0,
	$header-right-padding:0,
	$header-bottom-padding:0,
	$header-left-padding:0,
	$header-attachment:relative,
	$header-nav-position:none,
	$header-background:#FFF,
	$header-zindex:1,

	$logo-class:'.header-logo',
	$logo-width:auto,
	$logo-height:auto,
	$logo-position:none,
	$logo-top-margin:0,
	$logo-right-margin:0,
	$logo-bottom-margin:0,
	$logo-left-margin:0,

	$nav-class:'.header-nav',
	$nav-position:none,
	$nav-top-margin:0,
	$nav-right-margin:0,
	$nav-bottom-margin:0,
	$nav-left-margin:0,

	$nav-list:none,
	$nav-list-side:0,
	$nav-list-top:0,
	$responsive:'false',

	$responsive-color:$normal,
	$responsive-background:#FFF,
	$responsive-border:#EEE,
	$responsive-shadow:false
){
	width:$header-width;
	height:$header-height;
	position:$header-attachment;
	top:$header-x;
	left:$header-y;
	padding:$header-top-padding $header-right-padding $header-bottom-padding $header-left-padding;
	background:$header-background;
	z-index:$header-zindex;
	#{$logo-class}{
		float:$logo-position;
		margin:$logo-top-margin $logo-right-margin $logo-bottom-margin $logo-left-margin;
		img{
			width:$logo-width;
			height:$logo-height;
		}
	}
	#{$nav-class}{
		float:$nav-position;
		margin:$nav-top-margin $nav-right-margin $nav-bottom-margin $nav-left-margin;
		li{
			list-style:none;
			margin-top:$nav-list-top;
			float:$nav-list;
			@if $nav-list == left {
				margin-left:$nav-list-side;
				&:first-child{
					margin-left:0;
				}
			}
			@if $nav-list == right {
				margin-right:$nav-list-side;
				&:first-child{
					margin-right:0;
				}
			}
		}
		@if $responsive == 'false'{
			ul{
				display:block !important;
			}
		}
		@if $responsive == 'true'{
			background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
			background-size: 32px 64px;
			width:32px;
			height:32px;
			cursor:pointer;
			&.addClose{
				background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
				background-size: 32px 64px;
			}
			ul{
				@if $responsive-shadow == 'true'{
					-webkit-box-shadow:0 4px 4px rgba(0,0,0,.12);
					box-shadow:0 4px 4px rgba(0,0,0,.12);
				}
				position:absolute;
				width:100%;
				top:$header-height;
				left:0;
				display:none;
				li{
					margin:0;
					border-top:$responsive-border 1px solid;
					a{
						background:$responsive-background;
						color:$responsive-color;
						display:block;
						padding:$nav-list-top $nav-list-side;
						&:hover{
							opacity:.9;
						}
					}
				}
			}
		}
	}
}


#HEADER{
	/* PC STYLE
	----------------------------------------*/
	@media screen and (min-width:769px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$pc-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:0,
			$header-bottom-padding:0,
			$header-left-padding:30px,
			$header-attachment:fixed,
			$header-nav-position:none,
			$header-background:#FFF,
			$header-zindex:100,

			$logo-class:'.logo',
			$logo-width:240px,
			$logo-height:auto,
			$logo-position:left,
			$logo-top-margin:21px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:0,

			$nav-class:'.gnavi',
			$nav-position:right,
			$nav-top-margin:0,
			$nav-right-margin:0,
			$nav-bottom-margin:0,
			$nav-left-margin:0,

			$nav-list:left,
			$nav-list-side:0,
			$nav-list-top:23px
		);
	}

	/* SP STYLE
	----------------------------------------*/
	@media screen and (max-width:768px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$sp-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:15px,
			$header-bottom-padding:0,
			$header-left-padding:15px,
			$header-attachment:fixed,
			$header-nav-position:none,
			$header-background:#FFF,
			$header-zindex:100,

			$logo-class:'.logo',
			$logo-width:auto,
			$logo-height:24px,
			$logo-position:left,
			$logo-top-margin:20px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:0,

			$nav-class:'.gnavi',
			$nav-position:right,
			$nav-top-margin:14px,
			$nav-right-margin:0,
			$nav-bottom-margin:0,
			$nav-left-margin:0,

			$nav-list:none,
			$nav-list-side:12px,
			$nav-list-top:12px,

			$responsive:'true',
			$responsive-color:$normal,
			$responsive-background:#FFF,
			$responsive-border:#DDD,
			$responsive-shadow:'true'

		);
	}

	/* CUSTUM STYLE
	----------------------------------------*/
	@include trance(300ms);
	&.active{
		box-shadow: 0 0 32px rgba(#000,.08);
	}
	@media screen and (min-width:769px){
		min-width: $width;
		overflow: hidden;
		.gnavi{
			li{
				border-left: $border 1px solid;
				&:first-child{
					border: none;
				}
				&.contact{
					margin: 0;
					border: none;
					a{
						min-width: $pc-head;
						height: $pc-head;
						background: $blue;
						color: #FFF;
						padding-top: 26px;
						font-weight: 700;
						text-align: center;
						&:after{
							color: #FFF;
						}
						&:hover{
							background: lighten($blue,8%);
						}
					}
				}
				a{
					display: block;
					text-align: center;
					font-weight: 500;
					padding: 0 32px;
					line-height: 1;
					font-weight: 700;
					font-size: 13.5px;
					color: lighten($normal,8%);
					position: relative;
					@media screen and (max-width:1320px){
						font-size: 13px;
						padding: 0 20px;
					}
					@media screen and (max-width:1350px){
						font-size: 13px;
					}
					&:before{
						content: '';
						width: 0;
						height: 4px;
						background: $blue;
						position: absolute;
						bottom: -24px;
						left: 50%;
						transform: translateX(-50%);
						@include trance(120ms);
					}
					&:hover{
						&:before{
							width: 40px;
						}
					}
					&:after{
						content: attr(data-eng);
						display: block;
						font-size: 9px;
						margin: 10px 0 0;
						font-weight: 600;
						color: $normal;
					}
				}
			}
		}
	}
	a{
		color:$normal;
		@media screen and (max-width:768px){
			font-size: 16px;
		}
	}
}
