.WORKSSINGLE{
	&.bg{
		background:#F2F2F2;
	}
	&.first{
        .flex{
            @include flex($child:center);
            .left{
                width: 45%;
				@media screen and (max-width:768px){
					width: 90%;
				    margin-bottom: 16px;
				}
                dl{
                    dt{
                        border-bottom: 1px solid #111;
                        font-size: 20px;
						margin-bottom: 16px;
                    }
					dd{
						font-size: 16px;
					}
                }	
            }
            .right{
                width: 40%;
                margin-left: 5%;
				@media screen and (max-width:768px){
					width: 90%;
					margin-left: 0;
				}
                figure{
                    img{
                        width: 100%;
					    height: auto;
                        border: 10px solid #FFF;
                        box-shadow: 0 0 15px rgba(0,0,0,.15);
                    }
                }
            }
        }		
	}
	&.second{
		h3{
			color: #111;
			&::before{
				color: $blue;
			}
		}
		.second-flex{
			@include flex($child:center);
			li{
				width: 40%;
				margin: 2.5%;
				@media screen and (max-width:768px){
					width: 90%;
				    margin: 0 0 16px;
				}
				&:nth-child(2n+1){
					margin-left: 0;
				}
				&:nth-child(2n+2){
					margin-right: 0;
				}
				figure{
					img{
						width: 100%;
						box-shadow: 0 0 15px rgba(0,0,0,.15);
					}
				}
			}
		}	
	}
}