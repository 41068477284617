.SITEMAP{
	.nav{
		li{
			list-style:none;
			@include clum(4,23.5%,2%);
			text-align:center;
			a{
				display:block;
				background:$blue;
				border:$blue 1px solid;
				color:#FFF;
				padding:12px 0;
				border-radius: 4px;
				font-size:16px;
				font-weight:700;
				&:hover{
					background:#FFF;
					color:$blue;
				}
			}
		}
	}
	
	/* SP STYLE
	----------------------------------------*/
	@media screen and (max-width:768px){
		.nav {
			li {
				@include clum(1,100%,0);
				margin-bottom: 2% !important;
				a{
					font-size: 15px;
					padding: 10px 0;
				}
			}
		}
	}
}