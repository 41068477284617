@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #353535;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D00; }
    a:hover {
      text-decoration: none;
      color: #ff1111; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .INDEX.second .three:after, .SELLING.first .floating:after, .MODULE .tableStyle.formStyle tbody tr th:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    max-width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #353535;
    background: #FFF;
    font-size: 12px;
    line-height: 1.8;
    font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D00; }
    a:hover {
      text-decoration: none;
      color: #ff1111; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .INDEX.second .three:after, .SELLING.first .floating:after, .MODULE .tableStyle.formStyle tbody tr th:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    max-width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#HEADER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  @media screen and (min-width: 769px) {
    #HEADER {
      width: 100%;
      height: 80px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 0 0 30px;
      background: #FFF;
      z-index: 100; }
      #HEADER .logo {
        float: left;
        margin: 21px 0 0 0; }
        #HEADER .logo img {
          width: 240px;
          height: auto; }
      #HEADER .gnavi {
        float: right;
        margin: 0 0 0 0; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 23px;
          float: left;
          margin-left: 0; }
          #HEADER .gnavi li:first-child {
            margin-left: 0; }
        #HEADER .gnavi ul {
          display: block !important; } }
  @media screen and (max-width: 768px) {
    #HEADER {
      width: 100%;
      height: 66px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 15px 0 15px;
      background: #FFF;
      z-index: 100; }
      #HEADER .logo {
        float: left;
        margin: 20px 0 0 0; }
        #HEADER .logo img {
          width: auto;
          height: 24px; }
      #HEADER .gnavi {
        float: right;
        margin: 14px 0 0 0;
        background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
        background-size: 32px 64px;
        width: 32px;
        height: 32px;
        cursor: pointer; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 12px;
          float: none; }
        #HEADER .gnavi.addClose {
          background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
          background-size: 32px 64px; }
        #HEADER .gnavi ul {
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          position: absolute;
          width: 100%;
          top: 66px;
          left: 0;
          display: none; }
          #HEADER .gnavi ul li {
            margin: 0;
            border-top: #DDD 1px solid; }
            #HEADER .gnavi ul li a {
              background: #FFF;
              color: #353535;
              display: block;
              padding: 12px 12px; }
              #HEADER .gnavi ul li a:hover {
                opacity: .9; } }
  #HEADER.active {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.08); }
  @media screen and (min-width: 769px) {
    #HEADER {
      min-width: 1120px;
      overflow: hidden; }
      #HEADER .gnavi li {
        border-left: #e5e5e5 1px solid; }
        #HEADER .gnavi li:first-child {
          border: none; }
        #HEADER .gnavi li.contact {
          margin: 0;
          border: none; }
          #HEADER .gnavi li.contact a {
            min-width: 80px;
            height: 80px;
            background: #05a1f2;
            color: #FFF;
            padding-top: 26px;
            font-weight: 700;
            text-align: center; }
            #HEADER .gnavi li.contact a:after {
              color: #FFF; }
            #HEADER .gnavi li.contact a:hover {
              background: #25b2fb; }
        #HEADER .gnavi li a {
          display: block;
          text-align: center;
          font-weight: 500;
          padding: 0 32px;
          line-height: 1;
          font-weight: 700;
          font-size: 13.5px;
          color: #494949;
          position: relative; } }
      @media screen and (min-width: 769px) and (max-width: 1320px) {
        #HEADER .gnavi li a {
          font-size: 13px;
          padding: 0 20px; } }
      @media screen and (min-width: 769px) and (max-width: 1350px) {
        #HEADER .gnavi li a {
          font-size: 13px; } }
  @media screen and (min-width: 769px) {
          #HEADER .gnavi li a:before {
            content: '';
            width: 0;
            height: 4px;
            background: #05a1f2;
            position: absolute;
            bottom: -24px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transition: all 120ms ease-in-out;
            -moz-transition: all 120ms ease-in-out;
            -ms-transition: all 120ms ease-in-out;
            -o-transition: all 120ms ease-in-out;
            transition: all 120ms ease-in-out; }
          #HEADER .gnavi li a:hover:before {
            width: 40px; }
          #HEADER .gnavi li a:after {
            content: attr(data-eng);
            display: block;
            font-size: 9px;
            margin: 10px 0 0;
            font-weight: 600;
            color: #353535; } }
  #HEADER a {
    color: #353535; }
    @media screen and (max-width: 768px) {
      #HEADER a {
        font-size: 16px; } }

#FOOTER {
  background: #05a1f2;
  padding: 48px 0;
  color: #FFF; }
  @media screen and (max-width: 768px) {
    #FOOTER {
      padding: 32px 15px; } }
  #FOOTER a {
    color: #FFF; }
  #FOOTER .tit {
    float: left;
    width: 30%; }
    @media screen and (max-width: 768px) {
      #FOOTER .tit {
        width: auto;
        float: none; } }
    #FOOTER .tit dt {
      font-size: 24px;
      font-weight: 700; }
      #FOOTER .tit dt img {
        width: 300px; }
      @media screen and (max-width: 768px) {
        #FOOTER .tit dt {
          font-size: 20px; } }
    #FOOTER .tit dd.description {
      margin: 12px 0 0; }
      @media screen and (max-width: 768px) {
        #FOOTER .tit dd.description {
          margin: 4px 0 0; } }
  #FOOTER .fnav {
    width: 70%;
    float: right;
    padding: 0 0 0 72px; }
    @media screen and (max-width: 768px) {
      #FOOTER .fnav {
        width: auto;
        float: none;
        padding: 0; } }
    #FOOTER .fnav ul {
      float: right; }
      @media screen and (max-width: 768px) {
        #FOOTER .fnav ul {
          float: none;
          margin: 24px 0 0; } }
      #FOOTER .fnav ul li {
        list-style: none;
        float: left;
        font-size: 13px; }
        @media screen and (max-width: 768px) {
          #FOOTER .fnav ul li {
            float: none;
            font-size: 15px;
            margin: 0 0 1px; }
            #FOOTER .fnav ul li a {
              display: block;
              padding: 10px 15px;
              background: #FFF url(../../images/common/arrow_icon_glay_2x.png) no-repeat right 15px center;
              -moz-background-size: 5px;
              -webkit-background-size: 5px;
              -o-background-size: 5px;
              -ms-background-size: 5px;
              background-size: 5px;
              color: #353535;
              font-size: 13px;
              font-weight: 700;
              text-decoration: none; } }
        #FOOTER .fnav ul li:after {
          content: '|';
          margin: 0 8px;
          opacity: .64;
          display: inline-block; }
          @media screen and (max-width: 768px) {
            #FOOTER .fnav ul li:after {
              display: none; } }
        #FOOTER .fnav ul li:last-child:after {
          display: none; }
    #FOOTER .fnav small {
      display: block;
      clear: both;
      text-align: right;
      padding: 80px 0 0;
      font-size: 13px; }
      @media screen and (max-width: 768px) {
        #FOOTER .fnav small {
          padding: 24px 0 0;
          text-align: center; } }

.secondview {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center; }
  .secondview:before {
    content: '';
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .04; }
    @media only screen and (max-width: 768px) {
      .secondview:before {
        opacity: .5; } }
  .secondview .display {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    height: 280px; }
    @media only screen and (max-width: 768px) {
      .secondview .display {
        height: 140px; } }
  .secondview h2 {
    color: #023752;
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.24); }
    @media only screen and (max-width: 768px) {
      .secondview h2 {
        color: #fff;
        font-size: 28px; } }
    .secondview h2:after {
      content: attr(data-eng);
      font-size: 14px;
      margin: 16px 0 0;
      display: block;
      letter-spacing: .12em; }
      @media only screen and (max-width: 768px) {
        .secondview h2:after {
          font-size: 12px;
          margin: 8px 0 0; } }

#breadcrumb {
  padding: 14px 0;
  margin: 0 0 48px; }
  @media only screen and (max-width: 768px) {
    #breadcrumb {
      padding: 15px;
      background: #f5f5f5;
      margin: 0 0 32px; } }
  #breadcrumb ol {
    background: url(/images/common/home_icon_2x.png) no-repeat left 6px;
    background-size: 12px;
    padding: 0 0 0 24px; }
    @media only screen and (max-width: 768px) {
      #breadcrumb ol {
        background: url(/images/common/home_icon_2x.png) no-repeat left center;
        background-size: 12px;
        padding: 0 0 0 20px; } }
    #breadcrumb ol li {
      list-style: none;
      float: left;
      font-size: 13px;
      background: url(/images/common/arrow_2x.png) no-repeat right 8px;
      background-size: 5px;
      padding: 0 20px 0 0;
      margin: 0 16px 0 0; }
      @media only screen and (max-width: 768px) {
        #breadcrumb ol li {
          background: url(/images/common/arrow_2x.png) no-repeat right center;
          background-size: 3px;
          font-size: 10px;
          padding: 0 15px 0 0;
          margin: 0 10px 0 0; } }
      #breadcrumb ol li:last-child {
        padding: 0;
        margin: 0;
        background: none; }
      #breadcrumb ol li a {
        color: #353535; }
        #breadcrumb ol li a:hover {
          text-decoration: underline; }

.INDEX .firstview {
  width: calc(100% - 180px);
  height: calc(100vh - 80px - 72px) !important;
  min-height: 560px;
  max-height: 880px;
  overflow: visible !important;
  margin-left: 180px;
  background: #FFF;
  position: relative; }
  @media screen and (max-width: 768px) {
    .INDEX .firstview {
      width: auto;
      margin-left: 0; } }
  .INDEX .firstview:after {
    content: '';
    position: absolute;
    width: calc(40vh - 96px);
    height: calc(40vh - 96px);
    background: url(../../../images/index/triangle.png) no-repeat;
    background-size: cover;
    top: 6vh;
    left: -90px;
    z-index: 99;
    opacity: .12; }
    @media screen and (max-width: 768px) {
      .INDEX .firstview:after {
        top: 1vh;
        left: -64px; } }
  .INDEX .firstview .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -180px;
    z-index: 3; }
    @media screen and (max-width: 768px) {
      .INDEX .firstview .content {
        left: 0; } }
    .INDEX .firstview .content figure {
      position: absolute;
      left: 90px;
      bottom: 56px;
      width: 500px; }
      @media screen and (max-width: 768px) {
        .INDEX .firstview .content figure {
          left: 12px;
          bottom: 34px;
          width: 270px; } }
      .INDEX .firstview .content figure img {
        width: 100%; }
    .INDEX .firstview .content .sc {
      font-size: 12px;
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      white-space: nowrap;
      letter-spacing: .08em;
      position: absolute;
      top: 0;
      right: -140px;
      height: 100%;
      text-align: center;
      letter-spacing: .16em;
      font-weight: 700;
      transition: all .8s 0s ease-in-out; }
      @media screen and (max-width: 768px) {
        .INDEX .firstview .content .sc {
          display: none;
          /* top: 30px;
                    right: -150px;*/ } }
  .INDEX .firstview .hide {
    display: none; }

.INDEX.first .display {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.INDEX.first .news {
  width: 47.5%; }
  @media screen and (max-width: 768px) {
    .INDEX.first .news {
      width: 100%;
      margin-bottom: 32px; } }
  @media screen and (max-width: 768px) {
    .INDEX.first .news.cl {
      margin-bottom: 0; } }
  .INDEX.first .news.cl .list li time {
    background: #50c78d; }
  .INDEX.first .news.cl .list li:nth-child(2n) time {
    background: #2b865a; }
  .INDEX.first .news .box {
    border: #e5e5e5 1px solid;
    background: #FFF;
    padding: 20px;
    overflow-y: scroll;
    height: 160px; }
    @media screen and (max-width: 768px) {
      .INDEX.first .news .box {
        margin: 24px 0 0;
        padding: 15px;
        display: block;
        padding: 24px 15px 15px;
        border: #e5e5e5 1px dashed; } }
  .INDEX.first .news .list li {
    list-style: none;
    border-bottom: #e5e5e5 1px solid;
    padding: 0 0 10px;
    margin: 0 0 10px; }
    @media screen and (max-width: 768px) {
      .INDEX.first .news .list li {
        padding: 0 0 15px;
        margin: 0 0 15px; } }
    .INDEX.first .news .list li:last-child {
      margin: 0;
      padding: 0;
      border: none; }
    .INDEX.first .news .list li:nth-child(2n) time {
      background: #05a1f2; }
    .INDEX.first .news .list li a {
      display: block;
      text-decoration: none;
      color: #353535; }
    .INDEX.first .news .list li time,
    .INDEX.first .news .list li p.tit {
      vertical-align: middle; }
    .INDEX.first .news .list li time {
      width: 88px;
      background: #61c7fc;
      color: #FFF;
      display: inline-block;
      margin: 0 12px 0 0;
      text-align: center;
      font-size: 12px;
      padding: 2px 0; }
      @media screen and (max-width: 768px) {
        .INDEX.first .news .list li time {
          font-size: 11px; } }
    .INDEX.first .news .list li .tit {
      display: inline-block;
      font-size: 15px; }

.INDEX.second {
  background: url(../../../images/index/session.jpg) no-repeat center;
  background-size: cover;
  position: relative;
  backdrop-filter: blur(3px); }
  .INDEX.second:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .8; }
  .INDEX.second .context {
    text-align: center;
    font-size: 16px;
    padding: 32px 8px;
    background: #FFF;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.08); }
  .INDEX.second .three {
    margin: 32px 0 0; }
    .INDEX.second .three li {
      list-style: none;
      width: 32%;
      float: left;
      margin: 0 2% 2% 0;
      padding: 15px;
      background: #fefee3;
      border: #FFF 8px solid;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 2px 24px rgba(0, 0, 0, 0.12); }
      .INDEX.second .three li:nth-child(3n+1) {
        clear: both; }
      .INDEX.second .three li:nth-child(3n+3) {
        margin-right: 0; }
      .INDEX.second .three li:nth-last-child(-n+3) {
        margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        .INDEX.second .three li {
          width: 100%;
          padding: 14px;
          margin: 0 0 2% 0 !important; } }
      .INDEX.second .three li i {
        color: #05a1f2;
        font-size: 24px;
        width: 10%; }
      .INDEX.second .three li .txt {
        padding: 0 0 0 12px;
        width: 90%;
        font-size: 15.5px;
        font-weight: 700; }

.INDEX.third {
  background: rgba(5, 161, 242, 0.08); }
  .INDEX.third .mes {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    font-weight: 700;
    color: #05a1f2;
    background: #FFF;
    border: #05a1f2 2px solid;
    font-size: 17px;
    padding: 12px 0;
    border-radius: 999px; }
    @media screen and (max-width: 768px) {
      .INDEX.third .mes {
        width: auto;
        border-radius: 8px;
        border: #05a1f2 1px dashed;
        padding: 15px 25px; } }
  .INDEX.third .estateList {
    padding: 32px 16px 16px; }
    @media screen and (max-width: 768px) {
      .INDEX.third .estateList {
        padding: 15px 0 0; } }
    .INDEX.third .estateList ul li {
      list-style: none;
      background: #FFF;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }
      .INDEX.third .estateList ul li.slick-slide {
        opacity: 1; }
      @media screen and (max-width: 768px) {
        .INDEX.third .estateList ul li {
          margin: 0; } }
      .INDEX.third .estateList ul li a {
        color: #353535;
        text-decoration: none; }
      .INDEX.third .estateList ul li .opacity {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4); }
        .INDEX.third .estateList ul li .opacity p {
          position: absolute;
          top: 35%;
          left: 35%;
          color: #fff;
          font-size: 26px;
          font-weight: 700; }
      .INDEX.third .estateList ul li figure {
        position: relative; }
        .INDEX.third .estateList ul li figure.pickup:after {
          content: 'PICK UP';
          position: absolute;
          top: -12px;
          right: -12px;
          font-size: 12px;
          font-weight: 700;
          width: 72px;
          height: 72px;
          border-radius: 50%;
          background: #f2ea05;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center; }
        .INDEX.third .estateList ul li figure.bukken:after {
          content: '商談中';
          position: absolute;
          top: -16px;
          right: -16px;
          font-size: 15px;
          font-weight: 700;
          width: 72px;
          height: 72px;
          border-radius: 50%;
          background: #545454;
          color: #fff;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center; }
        .INDEX.third .estateList ul li figure img {
          width: 100%;
          height: 160px; }
          @media screen and (max-width: 768px) {
            .INDEX.third .estateList ul li figure img {
              height: 200px; } }
        .INDEX.third .estateList ul li figure figcaption {
          position: absolute;
          right: 0;
          bottom: 0;
          color: #FFF;
          background: #05a1f2;
          padding: 2px 12px;
          font-size: 13px; }
      .INDEX.third .estateList ul li dl {
        padding: 8px; }
        .INDEX.third .estateList ul li dl dt {
          font-weight: 700;
          font-size: 16px;
          text-align: center; }
        .INDEX.third .estateList ul li dl dd {
          font-size: 13px;
          margin: 4px 0 0; }
          .INDEX.third .estateList ul li dl dd.land:before, .INDEX.third .estateList ul li dl dd.buld:before {
            background: #eee;
            width: 64px;
            padding: 2px 0;
            display: inline-block;
            margin: 0 12px 0 0;
            text-align: center;
            font-weight: 700;
            font-size: 13px; }
          .INDEX.third .estateList ul li dl dd.land:before {
            content: '土地'; }
          .INDEX.third .estateList ul li dl dd.buld:before {
            content: '建物'; }
          .INDEX.third .estateList ul li dl dd.price {
            font-weight: 700;
            margin: 16px 0 0;
            padding: 16px 0 0;
            border-top: #e5e5e5 1px solid;
            line-height: 1;
            font-size: 26px;
            color: #D22; }
            .INDEX.third .estateList ul li dl dd.price:before, .INDEX.third .estateList ul li dl dd.price:after {
              color: #353535;
              font-size: 13px;
              vertical-align: middle;
              -moz-transform: translateX(0) translateY(-2px);
              -webkit-transform: translateX(0) translateY(-2px);
              -o-transform: translateX(0) translateY(-2px);
              -ms-transform: translateX(0) translateY(-2px);
              transform: translateX(0) translateY(-2px); }
            .INDEX.third .estateList ul li dl dd.price:before {
              content: '価格';
              font-size: 13px;
              color: #FFF;
              padding: 5px 0;
              width: 64px;
              background: #aaa;
              text-align: center;
              display: inline-block;
              margin: 0 8px 0 0; }
            .INDEX.third .estateList ul li dl dd.price:after {
              content: '万円';
              display: inline-block;
              margin: 0 0 0 4px; }
          .INDEX.third .estateList ul li dl dd.houseSize {
            color: #111;
            margin: 10px 0 0;
            height: 14px;
            font-size: 14px;
            line-height: 1; }

.INDEX.fors .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .INDEX.fors .list li {
    width: 48.5%;
    border: #05a1f2 1px dashed;
    border-radius: 6px;
    padding: 25px; }
    @media screen and (max-width: 768px) {
      .INDEX.fors .list li {
        width: 100%;
        margin-bottom: 32px; } }
    .INDEX.fors .list li dl dt {
      color: #0594de;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      margin: 0 0 12px; }
    .INDEX.fors .list li dl dd {
      width: 32%;
      float: left;
      margin: 0 2% 0 0;
      padding: 12px 15px;
      background: #05a1f2;
      color: #FFF;
      font-size: 15px;
      text-align: center;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .INDEX.fors .list li dl dd {
          width: 100%;
          margin: 0 0 2% 0; } }
      .INDEX.fors .list li dl dd:nth-of-type(2n+2) {
        background: rgba(5, 161, 242, 0.64); }
      .INDEX.fors .list li dl dd.wide {
        width: 66%; }
        @media screen and (max-width: 768px) {
          .INDEX.fors .list li dl dd.wide {
            width: 100%; } }
      .INDEX.fors .list li dl dd:last-of-type {
        margin: 0; }
        @media screen and (max-width: 768px) {
          .INDEX.fors .list li dl dd:last-of-type {
            font-size: 14px; } }

.INDEX.fors .btnlist {
  margin: 32px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .INDEX.fors .btnlist li {
    list-style: none;
    width: 23.5%;
    border: #e5e5e5 1px solid;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .INDEX.fors .btnlist li {
        width: 100%;
        margin-bottom: 32px; } }
    .INDEX.fors .btnlist li a {
      background-size: 5px;
      border-radius: 4px;
      display: block;
      padding: 15px;
      color: #353535;
      font-size: 16px;
      font-weight: 700; }
      .INDEX.fors .btnlist li a figure img {
        width: 100%; }
      .INDEX.fors .btnlist li a figure figcaption {
        padding: 12px 0 0;
        text-align: center; }

.COMPANY.first {
  background: url(../../images/company/bg.png) no-repeat left calc(50% + 360px) bottom; }
  .COMPANY.first dl {
    text-align: center; }
    @media screen and (max-width: 768px) {
      .COMPANY.first dl {
        width: 88%;
        margin: 0 auto; } }
    .COMPANY.first dl dt {
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      font-size: 28px;
      font-weight: 700;
      color: #036ca2;
      letter-spacing: .32em;
      margin: 0 0 20px; }
      @media screen and (max-width: 768px) {
        .COMPANY.first dl dt {
          margin: 0 0 15px; } }
    .COMPANY.first dl dd {
      line-height: 2.4; }
      @media screen and (max-width: 768px) {
        .COMPANY.first dl dd {
          line-height: 2;
          text-align: justify; }
          .COMPANY.first dl dd br {
            display: none; } }

.COMPANY.second .grid figure {
  width: 16%;
  padding: 0 2% 0 0;
  float: left; }
  @media screen and (max-width: 768px) {
    .COMPANY.second .grid figure {
      width: 32%;
      margin: 0 2% 0 0; }
      .COMPANY.second .grid figure:last-of-type {
        margin: 0; } }
  .COMPANY.second .grid figure img {
    width: 100%; }

.COMPANY.second .grid dl {
  width: 50%;
  float: right; }
  @media screen and (max-width: 768px) {
    .COMPANY.second .grid dl {
      width: auto;
      float: none;
      clear: both;
      padding: 24px 0 0; } }
  .COMPANY.second .grid dl dt {
    color: #05a1f2;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 8px; }
    @media screen and (max-width: 768px) {
      .COMPANY.second .grid dl dt {
        font-size: 16px; } }
  .COMPANY.second .grid dl dd {
    font-size: 14px;
    text-align: justify; }

.COMPANY.second .text {
  clear: both;
  background: #FFF;
  padding: 30px 50px;
  margin: 40px 0 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.second .text {
      margin: 24px 0 0;
      padding: 15px; } }

@media screen and (max-width: 768px) {
  .COMPANY.third .tableStyle tbody tr th {
    text-align: left; } }

.COMPANY.third .tableStyle dl {
  width: 47%;
  float: left;
  border-right: rgba(0, 0, 0, 0.08) 1px solid; }
  .COMPANY.third .tableStyle dl:nth-of-type(2n+2) {
    float: right;
    border: none; }
  @media screen and (max-width: 768px) {
    .COMPANY.third .tableStyle dl {
      width: auto;
      float: none;
      margin: 0 0 24px;
      border: none; }
      .COMPANY.third .tableStyle dl:nth-of-type(2n+2) {
        margin: 0;
        float: none; } }
  .COMPANY.third .tableStyle dl dt {
    background: #05a1f2;
    color: #FFF;
    text-align: center;
    display: inline-block;
    padding: 2px 24px;
    margin: 0 0 12px; }
    @media screen and (max-width: 768px) {
      .COMPANY.third .tableStyle dl dt {
        display: block;
        text-align: center;
        padding: 4px 0; } }

.COMPANY.third #addMap {
  margin: 48px 0 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.third #addMap {
      margin: 24px 0 0;
      height: 270px; } }

.SITEMAP {
  /* SP STYLE
	----------------------------------------*/ }
  .SITEMAP .nav li {
    list-style: none;
    list-style: none;
    width: 23.5%;
    float: left;
    margin: 0 2% 2% 0;
    text-align: center; }
    .SITEMAP .nav li:nth-child(4n+1) {
      clear: both; }
    .SITEMAP .nav li:nth-child(4n+4) {
      margin-right: 0; }
    .SITEMAP .nav li:nth-last-child(-n+4) {
      margin-bottom: 0; }
    .SITEMAP .nav li a {
      display: block;
      background: #05a1f2;
      border: #05a1f2 1px solid;
      color: #FFF;
      padding: 12px 0;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700; }
      .SITEMAP .nav li a:hover {
        background: #FFF;
        color: #05a1f2; }
  @media screen and (max-width: 768px) {
    .SITEMAP .nav li {
      list-style: none;
      width: 100%;
      float: left;
      margin: 0 0 0 0;
      margin-bottom: 2% !important; }
      .SITEMAP .nav li:nth-child(1n+1) {
        clear: both; }
      .SITEMAP .nav li:nth-child(1n+1) {
        margin-right: 0; }
      .SITEMAP .nav li:nth-last-child(-n+1) {
        margin-bottom: 0; }
      .SITEMAP .nav li a {
        font-size: 15px;
        padding: 10px 0; } }

.SINGLE.list .oneClum > li {
  list-style: none;
  padding: 0 0 12px;
  margin: 0 0 12px; }
  @media screen and (max-width: 768px) {
    .SINGLE.list .oneClum > li {
      padding-bottom: 0; } }
  .SINGLE.list .oneClum > li a {
    display: block;
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    padding: 20px;
    color: #353535;
    font-size: 16px;
    background: url("../../images/common/arrow_03_2x.png") no-repeat right 20px center;
    -moz-background-size: 4px 7px;
    -webkit-background-size: 4px 7px;
    -o-background-size: 4px 7px;
    -ms-background-size: 4px 7px;
    background-size: 4px 7px; }
    @media screen and (max-width: 768px) {
      .SINGLE.list .oneClum > li a {
        padding: 15px;
        font-size: 13px;
        background-position: right 12px center; } }
    .SINGLE.list .oneClum > li a:hover {
      text-decoration: none;
      background-position: right 17px center;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -ms-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
    .SINGLE.list .oneClum > li a time {
      display: inline-block;
      padding: 3px 15px;
      background: #05a1f2;
      color: #FFF;
      font-size: 12px;
      margin-right: 12px; }
      @media screen and (max-width: 768px) {
        .SINGLE.list .oneClum > li a time {
          display: block;
          width: 100px;
          margin: 0 0 5px; } }
      .SINGLE.list .oneClum > li a time.green {
        background: #50c78d; }

.SINGLE.detail .dateWrite {
  display: block;
  border-top: 1px solid #e5e5e5;
  margin: 32px 0 0;
  padding: 12px 0 0; }

.SINGLE.detail .postlink {
  text-align: center; }

.WORKS .localNav {
  background: #f2f2f2;
  padding: 24px 56px;
  border-radius: 6px; }
  .WORKS .localNav ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    .WORKS .localNav ul li {
      list-style: none;
      width: 23%; }
      @media screen and (max-width: 768px) {
        .WORKS .localNav ul li {
          width: 100%; } }
      .WORKS .localNav ul li a {
        display: block;
        text-align: center;
        color: #353535;
        font-size: 17px;
        font-weight: 700;
        background: #FFF;
        border: #FFF 1px solid;
        border-radius: 4px;
        padding: 12px 0;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.12); }
        .WORKS .localNav ul li a.active {
          color: #05a1f2;
          background: #FFF;
          border: #05a1f2 1px solid; }
        .WORKS .localNav ul li a:hover {
          background: #05a1f2;
          border: #05a1f2 1px solid;
          color: #FFF; }

.WORKS .listable {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }
  .WORKS .listable li {
    list-style: none;
    width: 23%;
    background: #FFF;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    margin: 0 1% 24px; }
    @media screen and (max-width: 768px) {
      .WORKS .listable li {
        width: 90%;
        margin: 0 auto 32px; } }
    .WORKS .listable li a {
      display: block; }
    .WORKS .listable li figure img {
      width: 100%;
      height: auto; }
    .WORKS .listable li figure figcaption {
      text-align: center;
      font-size: 14px;
      padding: 8px 15px;
      color: #353535; }

.SELLING.first p.context {
  padding: 32px 16px;
  background: rgba(5, 161, 242, 0.08);
  border-radius: 6px;
  text-align: center;
  font-size: 17px;
  color: #03517a;
  font-weight: 700; }

.SELLING.first .floating {
  margin: 0 0 40px; }
  .SELLING.first .floating:last-of-type {
    margin: 0; }
  .SELLING.first .floating figure {
    width: 30%;
    float: left; }
    @media screen and (max-width: 768px) {
      .SELLING.first .floating figure {
        width: 80%;
        margin: 0 auto 32px;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.04);
        float: none; } }
    .SELLING.first .floating figure img {
      width: 100%; }
  .SELLING.first .floating .text {
    width: 70%;
    float: right;
    padding: 0 0 0 40px; }
    @media screen and (max-width: 768px) {
      .SELLING.first .floating .text {
        width: 96%;
        float: none;
        margin: auto;
        padding: 0; } }
    .SELLING.first .floating .text dt {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 16px;
      position: relative; }
      @media screen and (max-width: 768px) {
        .SELLING.first .floating .text dt {
          font-size: 16px; } }
      .SELLING.first .floating .text dt.dotto {
        font-size: 20px;
        border-bottom: #e5e5e5 1px solid;
        padding: 0 0 12px 20px; }
        .SELLING.first .floating .text dt.dotto:before {
          content: '';
          width: 13px;
          height: 13px;
          border: #05a1f2 4px solid;
          border-radius: 50%;
          position: absolute;
          top: 12px;
          left: 0; }
    .SELLING.first .floating .text dd {
      text-align: justify; }

.ESTATE.first dl.mes {
  text-align: center; }
  .ESTATE.first dl.mes dt {
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 8px; }
    .ESTATE.first dl.mes dt:before {
      content: attr(data-eng);
      font-size: 11px;
      font-weight: 700;
      color: #05a1f2;
      display: block;
      line-height: 1;
      letter-spacing: .24em;
      margin: 0 0 6px; }
  .ESTATE.first dl.mes dd {
    font-size: 16px; }
    .ESTATE.first dl.mes dd strong {
      color: #0490d9; }

.ESTATE.second .listable {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .ESTATE.second .listable li {
    list-style: none;
    width: 48%;
    background: #FFF;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    padding: 24px;
    margin: 0 0 4%; }
    @media screen and (max-width: 768px) {
      .ESTATE.second .listable li {
        width: 98%;
        margin: 0 auto 32px; } }
    .ESTATE.second .listable li:nth-last-child(-n+2) {
      margin: 0; }
      @media screen and (max-width: 768px) {
        .ESTATE.second .listable li:nth-last-child(-n+2) {
          margin: 0 auto 32px; } }
    .ESTATE.second .listable li figure {
      margin: 0 0 32px; }
      .ESTATE.second .listable li figure img {
        width: 100%; }
    .ESTATE.second .listable li dl dt {
      text-align: center;
      font-weight: 700;
      font-size: 26px;
      line-height: 1;
      margin: 0 0 24px;
      padding: 0 0 24px;
      color: #0486ca;
      border-bottom: #e5e5e5 1px solid; }
      .ESTATE.second .listable li dl dt:before {
        content: attr(data-eng);
        font-size: 11px;
        display: block;
        color: #353535;
        margin: 0 0 12px; }
    .ESTATE.second .listable li dl dd {
      text-align: justify;
      margin: 0 0 20px; }
      .ESTATE.second .listable li dl dd:last-of-type {
        margin: 0;
        padding: 24px;
        background: rgba(5, 161, 242, 0.08);
        border-radius: 6px; }

.RENTAL.first .cap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .RENTAL.first .cap li {
    list-style: none;
    width: 32%;
    background: #FFF;
    border: #05a1f2 1px solid;
    border-radius: 6px;
    margin: 0 0 16px; }
    @media screen and (max-width: 768px) {
      .RENTAL.first .cap li {
        width: 96%;
        margin: 0 auto 16px; } }
    .RENTAL.first .cap li:nth-last-child(-n+3) {
      margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        .RENTAL.first .cap li:nth-last-child(-n+3) {
          margin-bottom: 16px; } }
    .RENTAL.first .cap li dl dt {
      font-size: 16px;
      text-align: center;
      background: #05a1f2;
      color: #FFF;
      padding: 4px 0;
      margin: 0 !important; }
    .RENTAL.first .cap li dl dd {
      padding: 12px 15px;
      font-size: 13px;
      text-align: justify; }

.CONTACT-TOP ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .CONTACT-TOP ul li {
    list-style: none;
    width: 48%;
    margin: 0 1% 2%; }
    @media screen and (max-width: 768px) {
      .CONTACT-TOP ul li {
        width: 100%; } }
    .CONTACT-TOP ul li dl {
      background: #f2f2f2;
      display: block;
      padding: 32px; }
      .CONTACT-TOP ul li dl dt {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px; }
        @media screen and (max-width: 768px) {
          .CONTACT-TOP ul li dl dt {
            font-size: 18px; } }
      .CONTACT-TOP ul li dl dd {
        margin-bottom: 8px; }
      .CONTACT-TOP ul li dl .btn {
        margin-top: 16px; }
        @media screen and (max-width: 768px) {
          .CONTACT-TOP ul li dl .btn {
            width: 100%; } }
        .CONTACT-TOP ul li dl .btn a {
          color: #fff;
          font-weight: bold;
          background: #05a1f2;
          padding: 16px;
          transition: .3s;
          border-radius: 8px; }
          .CONTACT-TOP ul li dl .btn a:hover {
            border: 1px solid #111;
            background: #f2f2f2;
            color: #111; }

.WORKSSINGLE.bg {
  background: #F2F2F2; }

.WORKSSINGLE.first .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .WORKSSINGLE.first .flex .left {
    width: 45%; }
    @media screen and (max-width: 768px) {
      .WORKSSINGLE.first .flex .left {
        width: 90%;
        margin-bottom: 16px; } }
    .WORKSSINGLE.first .flex .left dl dt {
      border-bottom: 1px solid #111;
      font-size: 20px;
      margin-bottom: 16px; }
    .WORKSSINGLE.first .flex .left dl dd {
      font-size: 16px; }
  .WORKSSINGLE.first .flex .right {
    width: 40%;
    margin-left: 5%; }
    @media screen and (max-width: 768px) {
      .WORKSSINGLE.first .flex .right {
        width: 90%;
        margin-left: 0; } }
    .WORKSSINGLE.first .flex .right figure img {
      width: 100%;
      height: auto;
      border: 10px solid #FFF;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.WORKSSINGLE.second h3 {
  color: #111; }
  .WORKSSINGLE.second h3::before {
    color: #05a1f2; }

.WORKSSINGLE.second .second-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }
  .WORKSSINGLE.second .second-flex li {
    width: 40%;
    margin: 2.5%; }
    @media screen and (max-width: 768px) {
      .WORKSSINGLE.second .second-flex li {
        width: 90%;
        margin: 0 0 16px; } }
    .WORKSSINGLE.second .second-flex li:nth-child(2n+1) {
      margin-left: 0; }
    .WORKSSINGLE.second .second-flex li:nth-child(2n+2) {
      margin-right: 0; }
    .WORKSSINGLE.second .second-flex li figure img {
      width: 100%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

#WRAPPER, #FOOTER {
  position: relative;
  top: 80px; }
  @media screen and (max-width: 768px) {
    #WRAPPER, #FOOTER {
      top: 66px; } }

.MODULE.wrap {
  padding: 72px 0; }
  @media screen and (max-width: 768px) {
    .MODULE.wrap {
      padding: 40px 15px; } }
  .MODULE.wrap.noBottom {
    padding-bottom: 0; }
  .MODULE.wrap.noTop {
    padding-top: 0; }
  .MODULE.wrap.glay {
    background: #f2f2f2; }
  .MODULE.wrap.sky {
    background: #edf8fe; }

.MODULE h2.lang {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 24px;
  letter-spacing: .05em; }
  .MODULE h2.lang:before {
    content: attr(data-eng);
    font-size: 38px;
    font-weight: 900;
    display: block;
    padding: 0 0 8px;
    color: #059ae8; }

.MODULE h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 24px;
  letter-spacing: .05em; }
  .MODULE h3:before {
    content: attr(data-eng);
    font-size: 14px;
    display: block;
    padding: 0 0 8px; }

.MODULE .center {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px; }

.MODULE h3.borders {
  font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  padding: 0 0 8px 16px;
  margin: 0 0 20px;
  position: relative;
  text-align: left;
  top: 0;
  left: 0;
  border-bottom: rgba(0, 0, 0, 0.08) 1px solid; }
  .MODULE h3.borders.gr:before {
    background: #2b865a; }
  @media only screen and (max-width: 768px) {
    .MODULE h3.borders {
      font-size: 17px;
      margin: 0 0 12px; } }
  .MODULE h3.borders:before {
    content: '';
    width: 4px;
    height: 80%;
    background: #05a1f2;
    position: absolute;
    top: 0;
    left: 0; }
  .MODULE h3.borders .numb {
    color: #D22;
    font-size: 24px;
    line-height: 1;
    margin: 0 4px; }

.MODULE h4 {
  font-size: 20px;
  font-weight: 700;
  padding: 16px 0;
  margin: 0 0 32px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .MODULE h4 {
      font-size: 18px;
      margin: 0 0 24px; } }
  .MODULE h4.borderStyle {
    border-bottom: #e5e5e5 1px solid;
    border-top: #e5e5e5 1px solid;
    padding-left: 16px; }
    .MODULE h4.borderStyle:before {
      content: '';
      width: 4px;
      height: 50%;
      background: #05a1f2;
      position: absolute;
      top: 25%;
      left: 0; }
  .MODULE h4.boldStyle {
    border-bottom: #e5e5e5 1px solid;
    margin-bottom: 15px; }
    .MODULE h4.boldStyle a {
      display: inline-block;
      font-size: 12px;
      color: #FFF;
      padding: 5px 12px;
      position: absolute;
      top: 50%;
      right: 0;
      -moz-transform: translateX(0) translateY(-50%);
      -webkit-transform: translateX(0) translateY(-50%);
      -o-transform: translateX(0) translateY(-50%);
      -ms-transform: translateX(0) translateY(-50%);
      transform: translateX(0) translateY(-50%); }
    .MODULE h4.boldStyle.blue:first-letter {
      color: #05a1f2; }
    .MODULE h4.boldStyle.blue a {
      background: #05a1f2; }
    .MODULE h4.boldStyle.yellow:first-letter {
      color: #f2c305; }
    .MODULE h4.boldStyle.yellow:after {
      content: attr(data-title);
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      margin-left: 5%; }
    .MODULE h4.boldStyle.yellow a {
      background: #f2c305; }

.MODULE .formStyle {
  border: none;
  border-collapse: collapse;
  width: 100%;
  font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em; }
  .MODULE .formStyle tbody tr th, .MODULE .formStyle tbody tr td {
    padding: 32px 4px;
    border-bottom: #e5e5e5 1px solid;
    text-align: left;
    vertical-align: middle; }
    @media only screen and (max-width: 768px) {
      .MODULE .formStyle tbody tr th, .MODULE .formStyle tbody tr td {
        padding: 14px 2px; } }
  .MODULE .formStyle tbody tr th {
    width: 22%;
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .MODULE .formStyle tbody tr th {
        width: 100%;
        font-size: 16px;
        border-bottom: none;
        padding-bottom: 0; } }
    .MODULE .formStyle tbody tr th.hiss {
      position: relative;
      top: 0;
      left: 0; }
      .MODULE .formStyle tbody tr th.hiss:before {
        content: '必須';
        display: inline-block;
        padding: 4px 12px;
        color: #FFF;
        background: #0479b6;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -16px 0 0;
        border-radius: 4px; }
        @media only screen and (max-width: 768px) {
          .MODULE .formStyle tbody tr th.hiss:before {
            font-size: 12px;
            margin: -14px 0 0; } }
  .MODULE .formStyle tbody tr td {
    padding-left: 40px; }
    @media only screen and (max-width: 768px) {
      .MODULE .formStyle tbody tr td {
        padding-left: 0; } }
    .MODULE .formStyle tbody tr td input[type=text],
    .MODULE .formStyle tbody tr td input[type=tel],
    .MODULE .formStyle tbody tr td input[type=email],
    .MODULE .formStyle tbody tr td textarea {
      display: block;
      background: #f2f2f2;
      border: none;
      border-radius: 4px;
      font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 15px;
      width: 100%;
      padding: 8px;
      -webkit-transition: all 120ms ease-in-out;
      -moz-transition: all 120ms ease-in-out;
      -ms-transition: all 120ms ease-in-out;
      -o-transition: all 120ms ease-in-out;
      transition: all 120ms ease-in-out; }
      .MODULE .formStyle tbody tr td input[type=text]:focus,
      .MODULE .formStyle tbody tr td input[type=tel]:focus,
      .MODULE .formStyle tbody tr td input[type=email]:focus,
      .MODULE .formStyle tbody tr td textarea:focus {
        transform: scale(1.01);
        background: #FFF;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.16); }
    .MODULE .formStyle tbody tr td label {
      vertical-align: middle; }
      .MODULE .formStyle tbody tr td label input {
        display: inline-block;
        margin: 0 4px 0 0; }
    .MODULE .formStyle tbody tr td textarea {
      height: 200px; }
  @media only screen and (max-width: 768px) {
    .MODULE .formStyle {
      display: block; }
      .MODULE .formStyle tbody, .MODULE .formStyle tr, .MODULE .formStyle th, .MODULE .formStyle td {
        display: block; } }

.MODULE .send {
  margin: 32px 0 0;
  text-align: center; }
  .MODULE .send input {
    border: none;
    width: 240px;
    height: 48px;
    font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    background: #0594de;
    cursor: pointer; }
    .MODULE .send input:hover {
      background: #0486ca; }

.MODULE .btnArea {
  text-align: center;
  margin: 24px 0 0; }
  @media screen and (max-width: 768px) {
    .MODULE .btnArea {
      margin: 16px 0 0; } }
  .MODULE .btnArea a {
    display: inline-block;
    background-color: #05a1f2;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    padding: 12px 48px;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    @media screen and (max-width: 768px) {
      .MODULE .btnArea a {
        padding: 12px 0;
        display: block; } }
    .MODULE .btnArea a:hover {
      background-color: #25b2fb; }
    .MODULE .btnArea a.arw {
      background-image: url(../images/common/arrow_w_2x.png);
      background-repeat: no-repeat;
      background-position: right 16px center;
      -moz-background-size: 13px;
      -webkit-background-size: 13px;
      -o-background-size: 13px;
      -ms-background-size: 13px;
      background-size: 13px; }
      .MODULE .btnArea a.arw:hover {
        background-position: right 12px center; }

.MODULE .inputArea label {
  display: block; }
  .MODULE .inputArea label input[type=text],
  .MODULE .inputArea label input[type=email],
  .MODULE .inputArea label input[type=tel],
  .MODULE .inputArea label input[type=password] {
    width: 72%;
    font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    padding: 3px 8px;
    border: none;
    background: #F2F2F2;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px; }

.MODULE .tableStyle {
  border: rgba(0, 0, 0, 0.08) 1px solid;
  border-collapse: collapse;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .MODULE .tableStyle {
      display: block; }
      .MODULE .tableStyle tbody, .MODULE .tableStyle tr, .MODULE .tableStyle th, .MODULE .tableStyle td {
        display: block; } }
  .MODULE .tableStyle tbody tr th, .MODULE .tableStyle tbody tr td {
    padding: 15px 20px;
    border: rgba(0, 0, 0, 0.08) 1px solid;
    vertical-align: middle;
    text-align: left; }
    @media screen and (max-width: 768px) {
      .MODULE .tableStyle tbody tr th, .MODULE .tableStyle tbody tr td {
        padding: 15px; } }
  .MODULE .tableStyle tbody tr th {
    background: #f2f2f2;
    width: 24%;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .MODULE .tableStyle tbody tr th {
        width: 100%; } }
  .MODULE .tableStyle.formStyle {
    border: none; }
    .MODULE .tableStyle.formStyle tbody tr th {
      background: none;
      font-size: 16px; }
      .MODULE .tableStyle.formStyle tbody tr th.hiss:before {
        content: '必須';
        background: #05a1f2;
        float: right;
        font-size: 13px;
        padding: 4px 24px;
        color: #FFF; }
    .MODULE .tableStyle.formStyle tbody tr th, .MODULE .tableStyle.formStyle tbody tr td {
      padding: 40px 2px;
      text-align: left;
      border: none;
      border-bottom: #e5e5e5 1px solid; }
      @media screen and (max-width: 768px) {
        .MODULE .tableStyle.formStyle tbody tr th, .MODULE .tableStyle.formStyle tbody tr td {
          padding: 15px 0; } }
    .MODULE .tableStyle.formStyle tbody tr td {
      padding-left: 56px; }
      @media screen and (max-width: 768px) {
        .MODULE .tableStyle.formStyle tbody tr td {
          padding-left: 0; } }
    .MODULE .tableStyle.formStyle label.lb {
      display: inline-block;
      margin: 0 32px 0 0;
      font-size: 17px;
      cursor: pointer; }
      .MODULE .tableStyle.formStyle label.lb input[type=radio] {
        margin: 0 5px 0 0;
        vertical-align: baseline; }
      .MODULE .tableStyle.formStyle label.lb:last-of-type {
        margin: 0; }
    .MODULE .tableStyle.formStyle input[type=text],
    .MODULE .tableStyle.formStyle input[type=password],
    .MODULE .tableStyle.formStyle input[type=tel],
    .MODULE .tableStyle.formStyle input[type=email],
    .MODULE .tableStyle.formStyle textarea {
      font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 15px;
      border: none;
      padding: 6px 12px;
      width: 100%;
      background: #f2f2f2;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      -o-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      display: block;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -ms-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out; }
      .MODULE .tableStyle.formStyle input[type=text]:focus,
      .MODULE .tableStyle.formStyle input[type=password]:focus,
      .MODULE .tableStyle.formStyle input[type=tel]:focus,
      .MODULE .tableStyle.formStyle input[type=email]:focus,
      .MODULE .tableStyle.formStyle textarea:focus {
        background: #FFF;
        box-shadow: 0 0 36px rgba(0, 0, 0, 0.24); }
    .MODULE .tableStyle.formStyle textarea {
      height: 200px;
      max-height: 200px;
      min-height: 200px; }
      .MODULE .tableStyle.formStyle textarea:disabled {
        color: #05a1f2;
        font-weight: 700;
        background: #FFF;
        border: #e5e5e5 1px solid; }

.MODULE .sendBtn {
  text-align: center;
  margin: 32px 0 0; }
  .MODULE .sendBtn .mes {
    padding: 20px 0;
    color: #d22;
    font-size: 18px;
    font-weight: 700;
    display: none; }
  .MODULE .sendBtn input {
    font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    font-size: 20px;
    font-weight: 700;
    background: #05a1f2;
    color: #FFF;
    border: #05a1f2 1px solid;
    cursor: pointer;
    padding: 6px 80px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    margin: 0 8px; }
    @media screen and (max-width: 768px) {
      .MODULE .sendBtn input {
        padding: 6px 0;
        display: block;
        margin: 0 0 12px;
        width: 100%;
        font-size: 16px; } }
    .MODULE .sendBtn input:hover {
      background: #FFF;
      color: #05a1f2; }

.MODULE .inputBtn {
  background: #f2f2f2;
  color: #FFF;
  border: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer; }
  .MODULE .inputBtn:hover {
    opacity: .8; }
  .MODULE .inputBtn.ib {
    display: inline-block;
    width: 152px; }
  .MODULE .inputBtn.sb {
    width: 81px; }
  .MODULE .inputBtn.colorBlue {
    background: #05a1f2; }
  .MODULE .inputBtn.lightBlue {
    background: #4dc0fb; }

.MODULE .searchBlock .searchBox {
  margin: 0 0 12px; }
  .MODULE .searchBlock .searchBox.inline {
    display: inline-block;
    margin: 0 24px 0 0; }
    @media screen and (max-width: 768px) {
      .MODULE .searchBlock .searchBox.inline {
        display: block; } }
    .MODULE .searchBlock .searchBox.inline:last-of-type {
      margin: 0; }
  .MODULE .searchBlock .searchBox:last-of-type {
    margin: 0; }
  .MODULE .searchBlock .searchBox label.radioSet {
    cursor: pointer;
    font-weight: 500; }
    .MODULE .searchBlock .searchBox label.radioSet input[type=checkbox] {
      display: inline-block;
      margin: 0 10px 0 0;
      -moz-transform: translateX(0) translateY(-1px);
      -webkit-transform: translateX(0) translateY(-1px);
      -o-transform: translateX(0) translateY(-1px);
      -ms-transform: translateX(0) translateY(-1px);
      transform: translateX(0) translateY(-1px); }
    .MODULE .searchBlock .searchBox label.radioSet select {
      font-family: 'Open Sans' , "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 16px;
      border: #e5e5e5 2px solid;
      padding: 6px 8px; }
  .MODULE .searchBlock .searchBox .underText {
    font-size: 12px;
    margin: 3px 0 0; }

.MODULE .searchBlock:last-of-type {
  margin: 0; }

.MODULE #addMap {
  width: 100%;
  height: 300px; }
  .MODULE #addMap iframe {
    vertical-align: bottom; }

.MODULE .pager {
  text-align: center;
  margin: 32px 0 0; }
  .MODULE .pager a, .MODULE .pager span {
    line-height: 32px;
    display: inline-block;
    color: #05a1f2;
    background: #FFF;
    border: #05a1f2 1px solid;
    margin: 0 4px;
    text-decoration: none; }
  .MODULE .pager span {
    border: #05a1f2 1px solid;
    background: #05a1f2;
    color: #FFF; }
  .MODULE .pager a:hover {
    background: #FFF;
    color: #05a1f2; }

.fixed {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 100;
  -moz-transform: translateX(0) translateY(-50%);
  -webkit-transform: translateX(0) translateY(-50%);
  -o-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%); }
  @media screen and (max-width: 768px) {
    .fixed {
      display: none; } }

#addMap {
  width: 100%;
  height: auto; }
  @media screen and (max-width: 768px) {
    #addMap {
      height: 350px; } }
  #addMap iframe {
    vertical-align: bottom; }
