.INDEX{
	$margin : 180px;
	.firstview{
		width: calc(100% - #{$margin});
		height: calc(100vh - #{$pc-head} - 72px) !important;
		min-height: 560px;
		max-height: 880px;
		overflow: visible !important;
		margin-left: $margin;
		background: #FFF;
		position: relative;
        @media screen and (max-width:768px){
            width: auto;
			margin-left: 0;
        }
		&:after{
			content: '';
			position: absolute;
			width: calc(40vh - 96px);
			height:calc(40vh - 96px);
			background: url(../../../images/index/triangle.png) no-repeat;
			background-size: cover;
			top: 6vh;
			left: -$margin / 2;
			z-index: 99;
			opacity: .12;
            @media screen and (max-width:768px){
                top: 1vh;
                left: -64px;	 	
            }
		}
		.content{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: - $margin;
			z-index: 3;
			@media screen and (max-width:768px){
				left: 0;
			}
			figure{
				position: absolute;
				left:$margin / 2;
				bottom: 56px;
				width: 500px;
				@media screen and (max-width:768px){
                    left: 12px;
                    bottom: 34px;
                    width: 270px;
				} 
				img{
					width: 100%;
				}
			}
			.sc{
				font-size: 12px;
				-webkit-writing-mode: vertical-rl;
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				white-space: nowrap;
				letter-spacing: .08em;
				position: absolute;
				top: 0;
				right: - $margin + 40px;
				height: 100%;
				text-align: center;
				letter-spacing: .16em;
				font-weight: 700; 
				transition: all .8s 0s ease-in-out;
				@media screen and (max-width:768px){
					display: none;
                   /* top: 30px;
                    right: -150px;*/			
				}
			}
		}
		.hide{
			display: none;
		}
    }

	&.first{
		.display{
			@include flex();
		}
		.news{
			width: 47.5%;
			@media screen and (max-width:768px){
				width: 100%;
				margin-bottom: 32px;
			}
			&.cl{
                @media screen and (max-width:768px){
                margin-bottom: 0;
                }				
				.list{
					li{
						time{
							background: lighten($green,20%);
						}
						&:nth-child(2n){
							time{
								background: $green;
							}
						}
					}
				}
			}
			.box{
				border: $border 1px solid;
				background: #FFF;
				padding: 20px;
				overflow-y: scroll;
				height: 160px;
				@media screen and (max-width:768px){
					margin: 24px 0 0;
					padding: 15px;
					display: block;
					padding: 24px 15px 15px;
					border: $border 1px dashed;
				}
			}
			.list{
				li{
					list-style: none;
					border-bottom: $border 1px solid;
					padding: 0 0 10px;
					margin: 0 0 10px;
					@media screen and (max-width:768px){
						padding: 0 0 15px;
						margin: 0 0 15px;
					}
					&:last-child{
						margin: 0;
						padding: 0;
						border: none;
					}
					&:nth-child(2n){
						time{
							background: $blue;
						}
					}
					a{
						display: block;
						text-decoration: none;
						color: $normal;
					}
					time,
					p.tit{
						vertical-align: middle;
					}
					time{
						width: 88px;
						background: lighten($blue,20%);
						color: #FFF;
						display: inline-block;
						margin: 0 12px 0 0;
						text-align: center;
						font-size: 12px;
						padding: 2px 0;
						@media screen and (max-width:768px){
							font-size: 11px;	
						}
					}
					.tit{
						display: inline-block;
						font-size: 15px;
					}
				}
			}
		}
	}
	&.second{
		background: url(../../../images/index/session.jpg) no-repeat center;
		background-size: cover;
		position: relative;
		backdrop-filter: blur(3px);
		&:before{
			content: '';
			width: 100%;
			height: 100%;
			background: #FFF;
			position: absolute;
			top: 0;
			left: 0;
			opacity: .8;
		}
		.context{
			text-align: center;
			font-size: 16px;
    		padding: 32px 8px;
			background: #FFF;
			box-shadow: 0 2px 24px rgba(#000,.08);
		}
		.three{
			margin: 32px 0 0;
			@extend .clearfix;
			li{
				@include clum();
				padding: 15px;
				background: lighten($yellow,46%);
				border: #FFF 8px solid;
				@include flex();
				align-items: center;
				box-shadow: 0 2px 24px rgba(#000,.12);
				@media screen and (max-width:768px){
				    width: 100%;
					padding: 14px;
					margin: 0 0 2% 0 !important;
				}
				i{
					color: $blue;
					font-size: 24px;
					width: 10%;
				}
				.txt{
					padding: 0 0 0 12px;
					width: 90%;
					font-size: 15.5px;
					font-weight: 700;
				}
			}	
		}
	}
	&.third{
		background: rgba($blue,.08);
		.mes{
			text-align: center;
			width: 50%;
			margin: 0 auto;
			font-weight: 700;
			color: $blue;
			background: #FFF;
			border: $blue 2px solid;
			font-size: 17px;
			padding: 12px 0;
			border-radius: 999px;
			@media screen and (max-width:768px){
				width: auto;
				border-radius: 8px;
				border: $blue 1px dashed;
				padding: 15px 25px;
			}
		}
		.estateList{
			padding: 32px 16px 16px;
			@media screen and (max-width:768px){
				padding: 15px 0 0;
			}
			ul{
				li{
					list-style: none;
					background: #FFF;
					box-shadow: 0 2px 8px rgba(#000,.08);
					&.slick-slide{
						opacity: 1;
					}
					@media screen and (max-width:768px){
						margin: 0;
					}
					a{
						color: $normal;
						text-decoration: none;
					}
					.opacity{
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: rgba(0,0,0,.4);
						p{
							position: absolute;
							top: 35%;
							left: 35%;
							color: #fff;
							font-size: 26px;
							font-weight: 700;
						}
					}
					figure{
						position: relative;
						&.pickup{
							&:after{
								content: 'PICK UP';
								position: absolute;
								top: -12px;
								right: -12px;
								$size:72px;
								font-size: 12px;
								font-weight: 700;
								width: $size;
								height: $size;
								border-radius: 50%;
								background: $yellow;
								@include flex();
								align-items: center;
								justify-content: center;
							}
						}
						&.bukken{
							&:after{
								content: '商談中';
								position: absolute;
								top: -16px;
								right: -16px;
								$size:72px;
								font-size: 15px;
								font-weight: 700;
								width: $size;
								height: $size;
								border-radius: 50%;
								background: #545454;
								color: #fff;
								@include flex();
								align-items: center;
								justify-content: center;
							}
						}
						img{
							width: 100%;
						    height: 160px;
							@media screen and (max-width:768px){
								height: 200px;
							}
						}
						figcaption{
							position: absolute;
							right: 0;
							bottom: 0;
							color: #FFF;
							background: $blue;
							padding: 2px 12px;
							font-size: 13px;
						} 
					}
					dl{ 
						padding: 8px;
						dt{
							font-weight: 700;
							font-size: 16px;
							text-align: center;
						}
						dd{
							font-size: 13px;
							margin: 4px 0 0;
							&.land,
							&.buld {
								&:before{
									background: #eee;
									width: 64px;
									padding: 2px 0;
									display: inline-block;
									margin: 0 12px 0 0;
									text-align: center;
									font-weight: 700;
									font-size: 13px;
								}
							}
							&.land{
								&:before{
									content: '土地';
								}
							}
							&.buld{
								&:before{
									content: '建物';
								}
							}
							&.price{
								font-weight: 700;
								margin: 16px 0 0;
								padding: 16px 0 0;
								border-top:$border 1px solid;
								line-height: 1;
								font-size: 26px;
								color: $red;
								&:before,
								&:after{
									color: $normal;
									font-size: 13px;
									vertical-align: middle;
									@include transform(0,-2px);
								}
								&:before{
									content: '価格';
									font-size: 13px;
									color: #FFF;
									padding: 5px 0;
									width: 64px;
									background: #aaa;
									text-align: center;
									display: inline-block;
									margin: 0 8px 0 0;
								}
								&:after{
									content: '万円';
									display: inline-block;
									margin: 0 0 0 4px;
								}
							}
						&.houseSize{
							   color: #111;
							   margin: 10px 0 0;
							   height: 14px;
							   font-size: 14px;
							   line-height: 1;
							}
						} 

					}
				} 
			}
		}
	}
	&.fors{
		.list{
			@include flex();
			li{
				width: 48.5%;
				border: $blue 1px dashed;
				border-radius: 6px;
				padding: 25px;
                @media screen and (max-width:768px){
                    width: 100%;
				    margin-bottom: 32px;
                } 
				dl{
					dt{
						color: darken($blue,4%);
						font-weight: 700;
						font-size: 20px;
						text-align: center;
						margin: 0 0 12px;
					}
					dd{
						width: 32%;
						float: left;
						margin: 0 2% 0 0;
						padding: 12px 15px;
						background: $blue;
						color: #FFF;
						font-size: 15px;
						text-align: center;
						font-weight: 700;
						@media screen and (max-width:768px){
							width: 100%;
							margin: 0 0 2% 0;
						}  
						&:nth-of-type(2n+2){
							background: rgba($blue,.64);
						}
						&.wide{
							width: 66%;
                            @media screen and (max-width:768px){
                                width: 100%;
                            }
						}
						&:last-of-type{
							margin: 0;
                            @media screen and (max-width:768px){
    							font-size: 14px;
                            }
						}
					}
				}
			}
		}
		.btnlist{
			margin: 32px 0 0;
			@include flex();
			li{
				list-style: none;
				width: 23.5%;
				border: $border 1px solid;
				box-shadow: 0 0 8px rgba(#000,.12);
				border-radius: 6px;
				overflow: hidden;
                @media screen and (max-width:768px){
                    width: 100%; 
					margin-bottom: 32px;
                }
				a{
					background-size: 5px;
					border-radius: 4px;
					display: block;
					padding: 15px;
					color: $normal;
					font-size: 16px;
					font-weight: 700;
					figure{
						img{
							width: 100%;
						}
						figcaption{
							padding: 12px 0 0;
							text-align: center;
						}
					}
				}
			}
		}
	}
}




