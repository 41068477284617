.ESTATE{
	&.first{
		dl.mes{
			text-align: center;
			dt{
				font-size: 26px;
				font-weight: 700;
				margin: 0 0 8px;
				&:before{
					content: attr(data-eng);
					font-size: 11px;
					font-weight: 700;
					color: $blue;
					display: block;
					line-height: 1;
					letter-spacing: .24em;
					margin: 0 0 6px;
				}
			}
			dd{
				font-size: 16px;
				strong{
					color: darken($blue,5%);
				}
			}
		}
	}
	&.second{
		.listable{
			@include flex();
			li{
				list-style: none;
				width: 48%;
				background: #FFF;
				box-shadow: 0 8px 32px rgba(#000,.12);
				padding: 24px;
				margin: 0 0 4%;
				@media screen and (max-width:768px){
					width: 98%;
					margin: 0 auto 32px; 
				}
				&:nth-last-child(-n+2){
					margin: 0;
                    @media screen and (max-width:768px){
                        margin: 0 auto 32px; 
                    }
				}
				figure{
					margin: 0 0 32px;
					img{
						width: 100%;
					}
				}
				dl{
					dt{
						text-align: center;
						font-weight: 700;
						font-size: 26px;
						line-height: 1;
						margin: 0 0 24px;
						padding: 0 0 24px;
						color: darken($blue,8%);
						border-bottom: $border 1px solid;
						&:before{
							content: attr(data-eng);
							font-size: 11px;
							display: block;
							color: $normal;
							margin: 0 0 12px;
						}
					}
					dd{
						text-align: justify;
						margin: 0 0 20px;
						&:last-of-type{
							margin: 0;
							padding: 24px;
							background: rgba($blue,.08);
							border-radius: 6px;
						}
					}
				}
			}
		}
	}
}